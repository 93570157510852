import { skipHydrate } from 'pinia'
export const useNuxtStore = defineStore('nuxtStore', () => {
  const userInfo = useLocalStorage('userInfo', {})
  const platform = useLocalStorage('platform', 'ALL')

  const setUserInfo = infos => {
    userInfo.value = infos
  }
  const setPlatform = val => {
    platform.value = val
  }
  return {
    userInfo: skipHydrate(userInfo),
    platform: skipHydrate(platform),
    setUserInfo,
    setPlatform
  }
})
